import { createRouter, createWebHistory } from 'vue-router'
import MidjouneryPage from '../views/MidjouneryPage.vue'

const routes = [
  {
    path: '/',
    name: 'mj',
    // redirect: '/imgList/list'
    component: MidjouneryPage
  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  },
  {
    path: '/draw',
    name: 'draw',
    component: () => import(/* webpackChunkName: "imgList" */ '../views/mjHistory/imgList.vue')
    // component: MidjouneryPage,
  },
  {
    path: '/payMonth',
    redirect: () => {
      window.location.href='https://www.topschat.com/me/payMonth';
    }
  },
  {
    path: '/homePage',
    name: 'homePage',
    component: () => import(/* webpackChunkName: "homePage" */ '../views/mjImage/homePage.vue')
  },
  {
    path: '/image/detail/:id',
    name: 'imageDetail',
    component: () => import(/* webpackChunkName: "imageDeatil" */ '../views/mjImage/imageDetail.vue')
  },
  // {
  //   path: '/imgList',
  //   name: 'imgLists',
  //   children: [{
  //     path: 'list',
  //     name: 'imgList',
  //     component: () => import(/* webpackChunkName: "imgList" */ '../views/mjHistory/imgList.vue')
  //   },
  //   {
  //     path: 'detail',
  //     name: 'imgDetail', 
  //     component: () => import(/* webpackChunkName: "imgDetail" */ '../views/mjHistory/imgDetail.vue')
  //   }
  // ]
  // }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
