<template>
    <div class="answerWarpcss">
       
        <!-- <el-image style="width: 100px;margin-left: 50px;" :src="imgRowData.url" :fit="fit" :preview-src-list="srcList">
            <template #error>
                <div class="image-slot">
                    <div class="image-slot">Loading<span class="dot">...</span></div>
                </div>
            </template>
        </el-image> -->
        <div style="position: absolute;">
            <div style="margin-left: 50px; width: calc(100% - 35px); margin-bottom: 10px">
                <el-progress :stroke-width="10" :percentage="imgRowData.sessionID ? 100 : imgRowData.progress" />
            </div>
            <el-image
                v-if="imgRowData.url != undefined"
                style="height: 600px;margin-left: 50px; cursor: pointer;" :src="imgRowData.url"
                :fit="fit"
                :preview-src-list="srcList"
                @click="getImgDetail"
            >
                <template #error>
                    <div class="image-slot">
                        <div class="image-slot">Loading<span class="dot">...</span></div>
                    </div>
                </template>
            </el-image>
            <div v-show="imgRowData.seedId" style="width: 100%; text-align: right; color: rgb(204, 204, 204);">seedId: {{ imgRowData.seedId }}</div>
            <div v-if="imgRowData.sessionID && imgRowData.type != 'upscale' && imgRowData.type != 'resolution' && imgRowData.type != 'quality'" style="margin-left: 50px;margin-top: 10px;">
                <el-button v-show="imgRowData.type != 'upscale'&& imgRowData.type != 'resolution' && imgRowData.type != 'quality'" style="width: 60px;" type="info" :disabled="!paintingFinish" @click="upscale(1)">U1</el-button>
                <el-button v-show="imgRowData.type != 'upscale'&& imgRowData.type != 'resolution' && imgRowData.type != 'quality'" style="width: 60px;" type="info" :disabled="!paintingFinish" @click="upscale(2)">U2</el-button>
                <el-button v-show="imgRowData.type != 'upscale'&& imgRowData.type != 'resolution' && imgRowData.type != 'quality'" style="width: 60px;" type="info" :disabled="!paintingFinish" @click="upscale(3)">U3</el-button>
                <el-button v-show="imgRowData.type != 'upscale'&& imgRowData.type != 'resolution' && imgRowData.type != 'quality'" style="width: 60px;" type="info" :disabled="!paintingFinish" @click="upscale(4)">U4</el-button>
                <el-button type="info" @click="reImagine(4)" :disabled="!paintingFinish">
                    <el-icon><Refresh /></el-icon>
                </el-button>
            </div>
            <div v-if="imgRowData.sessionID" style="margin-left: 50px;margin-top: 15px;margin-bottom: 10px;">
                <el-button v-show="imgRowData.type != 'upscale' && imgRowData.type != 'resolution' && imgRowData.type != 'quality'" style="width: 60px;" type="info" :disabled="!paintingFinish" @click="variation(1)">V1</el-button>
                <el-button v-show="imgRowData.type != 'upscale' && imgRowData.type != 'resolution' && imgRowData.type != 'quality'" style="width: 60px;" type="info" :disabled="!paintingFinish" @click="variation(2)">V2</el-button>
                <el-button v-show="imgRowData.type != 'upscale' && imgRowData.type != 'resolution' && imgRowData.type != 'quality'" style="width: 60px;" type="info" :disabled="!paintingFinish" @click="variation(3)">V3</el-button>
                <el-button v-show="imgRowData.type != 'upscale' && imgRowData.type != 'resolution' && imgRowData.type != 'quality'" style="width: 60px;" type="info" :disabled="!paintingFinish" @click="variation(4)">V4</el-button>
                <el-button v-show="imgRowData.type == 'upscale'" type="primary" @click="imgQuality" :disabled="!paintingFinish">超分辨率</el-button>
                <el-button v-show="imgRowData.type == 'upscale'" type="primary" @click="imgResolution" :disabled="!paintingFinish">画质增强</el-button>
                <el-button  title="从列表中删除" type="danger" @click="deletehistory()" :disabled="!paintingFinish">
                    <el-icon><Close /></el-icon>
                </el-button>
            </div>
        </div>
    </div>
  </template>
  
  <script>
  import midjounarylogo from '@/assets/logo.png';
  import { getCookie } from '../../utils/common.js';
  export default {
    props: ['imgRowData', 'paintingFinish'],
    data() {
        return {
            midjounarylogoUrl: midjounarylogo,
            useSurufa:false,
            isloading:false,
            disabled: false,
        }
    },
    methods: {
        upscale(index){
            const obj = {};
            obj.type = 'upscale';
            obj.discordMsgId = this.imgRowData.id;
            obj.msgHash = this.imgRowData.msgHash;
            obj.sessionID = this.imgRowData.sessionID;
            obj.index = index;
            obj.userId = JSON.parse(getCookie("user")).id;
            this.$emit('upscale', obj);
        },
        variation(index){
            const obj = {};
            obj.type = 'variation';
            obj.discordMsgId = this.imgRowData.id;
            obj.msgHash = this.imgRowData.msgHash;
            obj.sessionID = this.imgRowData.sessionID;
            obj.index = index;
            obj.userId = JSON.parse(getCookie("user")).id;
            this.$emit('variation', obj);
        },
        reImagine(){
            const obj = {};
            obj.type = "generate";
            obj.discordMsgId = "";
            obj.msgHash = "";
            obj.prompt = this.imgRowData.prompt;
            obj.index = 0;
            obj.userId = JSON.parse(getCookie("user")).id;
            this.$emit('reImagine', obj);
        },
        deletehistory(){
            this.$emit('deletehistory', this.imgRowData.id);
        },
        getImgDetail(){
            window.open(this.imgRowData.url, '_blank')
        },
        imgResolution(){
            this.$emit('imgResolution', this.imgRowData)
        },
        imgQuality(){
            this.$emit('imgQuality', this.imgRowData)
        }
    }
  }
  </script>
  <style scoped>
    .avatar {
        float: left;
        margin-top: 0 ;
        margin-right: 10px;
        min-width: 40px;
    }
  
    .answerWarpcss {
        margin-right: 15px;
        padding-left: 30px;
        padding-top: 30px;
        padding-bottom: 30px;
        position: relative;
    }
  
    .textcss {
        font-weight: 700;
        color: #DCF3F5;
        font-size: 14px;
    }
  
    .errortext { 
        color: #FD0017;
        background: #2B2D31;
        height: 100px;
        width: 476px;
        margin-top: 19px;
        margin-left: 48px;
        padding: 10px;
    }
  
    .describetext  {
        color: #fff;
        background: #2B2D31;
        width: 600px;
        margin-top: 19px;
        margin-left: 48px;
        padding: 10px;
        font-weight: 500;
    }
    .textwarpcss{
        min-height: 60px;
    }
  
    .textprogresscss {
        font-size: 12px;
        margin-left: 10px;
  
    }
  
  
    .image-slot {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
        background: var(--el-fill-color-light);
        color: var(--el-text-color-secondary);
        font-size: 14px;
    }
    .dot {
        animation: dot 2s infinite steps(3, start);
        overflow: hidden;
  }
  /deep/ .el-progress__text {
    color: #fff;
    font-size: 0.8em;
  }
  /deep/ .el-progress-bar__inner {
    background: linear-gradient(45deg,#409EFF, #3ac27a);
  }
  </style>