<template>
  <div>
    <!-- <div class="topbar">
      <div class="logo">
        <img style="width: 40px;" src="../assets/logo-color.png">
        <span style="
            margin-top: 5px;
            padding-left: 20px;
            display: inline-block;
            cursor: pointer;
            vertical-align: top;
            color: #fff;
            font-weight: 600;
            font-size: 1.5em
          " @click="backHome">鼎 言 智 绘</span>
      </div>
      <el-button type="primary" style="margin-top: 15px; float: right; margin-right: 20px;"
        @click="getHistory">查看生成记录</el-button>
    </div> -->
    <!-- <el-button type="primary" style="margin-top: 20px; position: absolute; z-index: 10; right: 160px;"
    @click="getSquare">前往广场
    </el-button> -->
    <span style="position: absolute; left: 520px; top: 15px; color: #c4c4c4; font-size: 1.2em;" v-show="userInfo.status != '2'">剩余体验次数：{{ tryTime }}</span>
    <el-button type="primary" style="margin-top: 20px; position: absolute; z-index: 10; right: 20px;"
        @click="getHistory">查看生成记录</el-button>
        <el-button type="primary" style="margin-top: 20px; position: absolute; z-index: 10; right: 160px;"
          @click="toBuy"
        >去购买</el-button>
    <div class="content">
      <div class="input-center">
        <div class="input-area" :style="qiniuImage.length ? {height: 1110 + uploadListLen * 18 + paramListLen *35 + 'px'} : {height: 1100 + uploadListLen * 18 + paramListLen * 35 + 'px'}">


          <div style="color: #fff; display: inline-block; padding: 10px; width: 100%; text-align: left; left: 10px;">
            <img style="width: 25px;" src="../assets/logo-color.png">
            <span style="margin-top: 3px; margin-left: 10px; display:inline-block; vertical-align: top;">文本生成图片</span>
          </div>
          <div class="input-public-css"
            style="width: calc(100% - 30px); padding: 15px; padding-bottom: 0px; height: 690px; background-color: #1d222c;">
            <el-input v-model="mjprompt" maxlength="800" placeholder="请输入提示词..." show-word-limit type="textarea" />
            <el-input v-model="negative_mjprompt" style="margin-top: 20px;" maxlength="800" placeholder="请输入否定提示词" show-word-limit type="textarea" />
            <div
              style="color: rgb(186, 180, 180); text-align: left; padding: 10px 0px; padding-bottom: 15px; font-size: 0.9em;">
              风格素描，图像比例1：1</div>
            <div style="height: 100%; height: 250px;">
              <div class="styleImgDiv" style="margin-left: 0;" @click="currType = 0">
                <img src="../assets/default.jpg" :class="currType == 0 ? 'chosen-styleImg' : 'styleImg'" />
                <div :class="currType == 0 ? 'chosen-overText' : 'overText'">默认</div>
              </div>

              <div class="styleImgDiv" @click="currType = 1">
                <img src="../assets/shuicai.jpg" :class="currType == 1 ? 'chosen-styleImg' : 'styleImg'" />
                <div :class="currType == 1 ? 'chosen-overText' : 'overText'">水彩</div>
              </div>

              <div class="styleImgDiv" @click="currType = 2">
                <img src="../assets/youhua.jpg" :class="currType == 2 ? 'chosen-styleImg' : 'styleImg'" />
                <div :class="currType == 2 ? 'chosen-overText' : 'overText'">油画</div>
              </div>

              <div class="styleImgDiv" @click="currType = 3">
                <img src="../assets/zhongguohua.jpg" :class="currType == 3 ? 'chosen-styleImg' : 'styleImg'" />
                <div :class="currType == 3 ? 'chosen-overText' : 'overText'">中国画</div>
              </div>

              <div class="styleImgDiv" style="margin-left: 0;" @click="currType = 4">
                <img src="../assets/bianpingchahua.jpg" :class="currType == 4 ? 'chosen-styleImg' : 'styleImg'" />
                <div :class="currType == 4 ? 'chosen-overText' : 'overText'">扁平插图</div>
              </div>

              <div class="styleImgDiv" @click="currType = 5">
                <img src="../assets/erciyuan.jpg" :class="currType == 5 ? 'chosen-styleImg' : 'styleImg'" />
                <div :class="currType == 5 ? 'chosen-overText' : 'overText'">二次元</div>
              </div>

              <div class="styleImgDiv" @click="currType = 6">
                <img src="../assets/sumiao.jpg" :class="currType == 6 ? 'chosen-styleImg' : 'styleImg'" />
                <div :class="currType == 6 ? 'chosen-overText' : 'overText'">素描</div>
              </div>

              <div class="styleImgDiv" @click="currType = 7">
                <img src="../assets/3Dkatong.jpg" :class="currType == 7 ? 'chosen-styleImg' : 'styleImg'" />
                <div :class="currType == 7 ? 'chosen-overText' : 'overText'">3D卡通</div>
              </div>
              <div style="width: 100%">
                <div class="styleImgDiv" style="float: left; margin-left: 10px" @click="currType = 8">
                  <img src="../assets/sheying.jpg" :class="currType == 8 ? 'chosen-styleImg' : 'styleImg'" />
                  <div :class="currType == 8 ? 'chosen-overText' : 'overText'">摄影模式</div>
                </div>
              </div>
            </div>
            <div style="width: 100%; display: flex; justify-content: space-around">
              <el-tag :class="currRate == 0 ? 'choseTag' : 'unchoseTag'" @click="currRate = 0"
              style="margin-left: 0;">比例：1:1</el-tag>
            <el-tag :class="currRate == 1 ? 'choseTag' : 'unchoseTag'" @click="currRate = 1">比例：16:9</el-tag>
            <el-tag :class="currRate == 2 ? 'choseTag' : 'unchoseTag'" @click="currRate = 2">比例：9:16</el-tag>
            </div>
            <div style="width: 100%; margin-top: 12px; text-align: left; margin-left: 5px">
              <el-tag :class="currRate == 3 ? 'choseTag' : 'unchoseTag'" @click="currRate = 3"
              style="margin-left: 0;">比例：5:7</el-tag>
            <el-tag :class="currRate == 4 ? 'choseTag' : 'unchoseTag'" style="margin-left: 34px;" @click="currRate = 4">比例：7:5</el-tag>
            </div>
          </div>


          <!-- 增加参数功能 2023/09/17 -->
          <div style="width: 100%; margin-top: 8px;text-align: left; ">
            <div class="styleHeaderDiv">
              参数名
            </div>
            <div class="styleHeaderDiv" style="width:42%;margin-left: 1px;">
              参数值
            </div>
            <div class="styleHeaderDiv" style="width:12%;margin-left: 1px;">
               操作
            </div>
          </div>
          <div style="width: 100%;text-align: left; background-color: RGB(73,87,98); ">
            <div  class="styleSelDiv" style="">
              <el-select  v-model="selectedValue" @change="handleSelectChange" placeholder="请选择" :popper-append-to-body="false"  popper-class="select-popper" >
                <el-option v-for="(label,item,idx) in paramData" :key="idx" :label="label" :value="item" />
              </el-select>
            </div>
            <div class="styleSelDiv"  style="width:42%;margin-left: 1px;">
              <el-input v-model="inputValue" placeholder="设置参数值" ></el-input>
            </div>
            <el-button style="width: 12%;height: 30px;margin-left:1px;" type="primary" @click="addItem" >确认</el-button>
            <!-- <el-image src="/icon_add.png"  @click="addItem"   style="width: 25px;height: 25px; display: inline-block; cursor: pointer; text-align: center;margin-left: 12px; transform: translate(0%, 25%);" ></el-image> -->
          </div>


          <div  v-for="(item, index) in paramList.value" :key="index"  style="width: 100%;text-align: left; margin-top: 1px;background-color: RGB(73,87,98); ">
            <div class="styleSelDiv"  style="">
              <el-input v-model="item.lable" :readonly="true"  ></el-input>
            </div>
            <div class="styleSelDiv"  style="width:42%;margin-left: 1px;">
              <el-input v-model="item.value"></el-input>
            </div>
            <el-image src="/icon_delete.png"  @click="delItem(item)"   style="width: 20px;height: 20px; display: inline-block; cursor: pointer; text-align: center;margin-left: 12px; transform: translate(0%, 25%);" ></el-image>
          </div>


          <!-- 增加参数功能 2023/09/17 -->

          <div style="width: 100%; text-align:left; margin-top:15px; margin-bottom: 10px; color: #fff">
            <span style="display: inline-block; line-height: 30px; vertical-align: top;">以图生图</span>
            <div style="display: inline-block; vertical-align: top; float: right;">
              <span style="font-size: 0.9em;">人像模式</span>
              <el-switch style="margin-left: 7px;" v-model="portraitMode"></el-switch>
            </div>
          </div>
          <el-upload
            ref="uploadRef"
            class="upload-demo"
            @change="uploadS3"
            :on-remove="handleRemove"
            :auto-upload="false"
            :disabled="!paintingFinish"
            drag
            multiple
          >
            <el-icon class="el-icon--upload"><upload-filled /></el-icon>
            <div class="el-upload__text">
              拖拽文件或 <em>点击上传</em>
            </div>
            <template #tip>
              <!-- <div class="el-upload__tip" style="color: #999999;">
                最多只支持3张垫图
              </div> -->
            </template>
          </el-upload>
          
          <div class="slider-demo-block" v-show="qiniuImage.length > 0">
            <div style="width: 100%; text-align:left; margin-bottom: 2px; font-size: 0.8em; color: #fff">垫图权重</div>
            <el-slider size="small" style="margin-bottom: 2px" v-model="qiniuiwval" :format-tooltip="formatTooltip" />
          </div>
          <el-dialog v-model="dialogVisible">
            <img w-full :src="dialogImageUrl" alt="Preview Image" />
          </el-dialog>
          <div
            style="color: rgb(186, 180, 180); width: 100%; text-align: left; font-size: 0.92em; padding-bottom: 15px;">
            示例: dog，hat，pencil，picture</div>
          <el-button style="width: 100%; height: 40px; margin-left: 0;" class="choseTag" type="primary" plain
            @click="getMjData()" :disabled="!paintingFinish">生成创意作画</el-button>
          <div class="input-public-css" style="width: 100%; height: 100px;"></div>
          <!-- <div class="input-public-css" style="width: 100%; height: 100px; background-color: #1d222c;"></div> -->
        </div>
      </div>
      <!-- <el-input size="large" v-model="mjprompt" @change="getMjData" class="input" placeholder="请输入提示词"/> -->
    </div>
    <div class="imgContent">
      <midjounery
        style="width: 100%; height: 730px; border-top: 1px solid rgb(87, 87, 87);"
        v-for="(item, index) in imgList"
        :imgRowData="item"
        :key="item"
        v-show="!(index == imgList.length - 1 && Loading)"
        @upscale="upscale"
        @variation="variation"
        @reImagine="reImagine"
        :paintingFinish="paintingFinish"
        @deletehistory="deletehistory"
        @imgResolution="imgResolution"
        @imgQuality="imgQuality"
      >
        {{ item }}
      </midjounery>
      <div class="loadingCss" element-loading-background="rgba(122, 122, 122, 0.3)" element-loading-text="请稍等，图片生成中..."
        v-show="Loading" v-loading="Loading"></div>
      <div class="tip" v-show="imgList.length == 0 && !Loading">
        <img style="margin-bottom: 4em" src="../assets/logo.png">
        <div class="tip-text">我是鼎言智绘</div>
        <div class="tip-text">我将帮助您完成您所需要的模型</div>
      </div>
    </div>
  </div>
</template>

<script setup>
import midjounery from './mjComponents/midjouneryItem.vue'
import { getCookie,isNumber } from '../utils/common.js';
import { onMounted, ref, toRaw, watch } from 'vue';
import { useRouter } from 'vue-router'
import { JumpTo } from '../utils/common'
import { ElMessage, ElMessageBox, ElNotification } from 'element-plus';
import * as qiniu from 'qiniu-js'
import axios from 'axios';
import AWS from 'aws-sdk';
const BUCKET_NAME = 'stablediffusion-sig'
const currType = ref(0);
const currRate = ref(0);
const mjprompt = ref('');
const negative_mjprompt = ref('');
const Loading = ref(false);
const paintingFinish = ref(true);
const router = useRouter();
const qiniuToken = ref('');
const uploadRef = ref(null);
const qiniuImage = ref([]);
const qiniuiwval = ref(10);
///////////////////////////////////////////
/*
 * 增加参数功能 2023/09/17
 */
const selectedValue = ref('select'); 
const inputValue = ref(''); 
const paramListLen = ref(0);
// const paramList =ref([]); 
const paramList = []; 
const paramData= {
  'select': '请选择',
  '--seed': 'Seed 种子',
  '--chaos': 'Chaos 随机度',
  '--stylize':'Stylize 风格化'
};
const paramValue= {
  'select': '',
  '--seed': '',
  '--chaos': '0',
  '--stylize':'100'
};
const handleSelectChange = (selectedObj) => {
  inputValue.value = paramValue[selectedObj];
};

const addItem= () => {
  if(selectedValue.value =='select'){
    ElMessage.warning('请选择参数名！');
    return;
  }
  inputValue.value = inputValue.value.replace(/\s/g, '');
  if(inputValue.value ==''){
    ElMessage.warning('请输入参数值！');
    return;
  }
  if(!paramList.value){
    paramList.value = [];
  }
  
  if (paramList.value.some(item => item.param === selectedValue.value)) {
    ElMessage.warning('所添加的参数[' + paramData[selectedValue.value] +']已存在！');
    return;
  }

  //Seed 种子 --seed <integer between 0–4294967295> 
  if(selectedValue.value =='--seed' && (!isNumber(inputValue.value) || (inputValue.value < 0 || inputValue.value > 4294967295 ))){
    ElMessage.warning('请设置参数[Seed 种子]的值在 0 到 4294967295 区间的数值！');
    return;
  }

  //Chaos 随机度 --chaos <number 0–100> 
  if(selectedValue.value =='--chaos' && (!isNumber(inputValue.value) || (inputValue.value < 0 || inputValue.value > 100 ))){
    ElMessage.warning('请设置参数[Chaos 随机度]的值在 0 到 100 区间的数值！');
    return;
  }

  //Stylize 风格化 --stylize <number 0–1000> 
  if(selectedValue.value =='--stylize' && (!isNumber(inputValue.value) || (inputValue.value < 0 || inputValue.value > 1000 ))){
    ElMessage.warning('请设置参数[Stylize 风格化]的值在 0 到 1000 区间的数值！');
    return;
  }
  let param = { param:selectedValue.value,value:inputValue.value,lable:paramData[selectedValue.value] };
  
  paramList.value.push(param);
  selectedValue.value = 'select';
  handleSelectChange(selectedValue.value );
  paramListLen.value = paramList.value.length;
};

const delItem= (value) => {
  paramList.value= paramList.value.filter(item => { 
    return item.param !== value.param;
  });
  selectedValue.value = value.param;
  handleSelectChange(selectedValue.value);
  selectedValue.value = 'select';
  handleSelectChange(selectedValue.value);
  paramListLen.value = paramList.value.length;
};


///////////////////////////////////////////

const getSquare = () => router.push('/homePage');

const formatTooltip = (val) => {
  return val / 50
}

const checkUserInfo = () => {
  if (userInfo.value.status == 2) {
    return true
  } else {
    if (tryTime.value == 0) {
      ElMessageBox.confirm(
        '您的体验次数已经使用完了，是否前往充值？',
        'Warning',
        {
          confirmButtonText: '去充值',
          cancelButtonText: '取消',
          type: 'warning',
        }
      ).then(() => {console.log('')}).catch(() => {})
        return false
    } else {
      ElMessageBox.confirm(
        `将扣除您1次体验次数，是否继续？（当前剩余${tryTime.value}次）`,
        'Warning',
        {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        }
      ).then(() => {
          ElMessage({
            type: 'success',
            message: 'Delete completed',
          })
          return true
        }).catch(() => false)
    }
  }
}

// ------------------------------------------------ 请求mj数据 ------------------------------------------------
const getMjData = (objData) => {
  if (mjprompt.value.length == 0) {
    ElMessage.warning('提示词不能为空噢, 请重新填写');
  } else {
    if (checkUserInfo()) {
      let xhr = new XMLHttpRequest();
      xhr.open("POST", "/v1/trigger/midjourney-bot", true);
      xhr.withCredentials = true;
      Loading.value = true;
      paintingFinish.value = false;
      imgList.value.push({
        url: '',
        id: '',
        msgHash: '',
        sessionID: ''
      });
      // 将 token 存储在 cookie 中并发送到服务器
      let token = getCookie("token");
      let userId;
      try {
        userId = JSON.parse(getCookie("user")).id;
      } catch (error) {
        ElMessage.warning('检测到您的账户信息异常，请重新登录');
        setTimeout(() => window.open('https://www.topschat.com', '_self'), 3000);
      }
      xhr.setRequestHeader('Cookie', `token=${token}`);
      xhr.setRequestHeader("Accept", "text/event-stream");
      xhr.setRequestHeader("Content-Type", "application/json");
      xhr.onreadystatechange = function () {
        if (xhr.readyState === 3) {
          console.log(xhr.response);
          if (xhr.response.includes('"status":"error"')) {
            ElMessage.warning('您输入的提示词包含敏感词汇噢 ~ ');
            imgList.value.pop();
            Loading.value = false;
          } else {
            getImgData(xhr.response);
          }
        }
        if (xhr.readyState === 4) {
          if (xhr.response == '{"code":1002,"message":"not login"}') {
            ElMessage.warning('您还未登录，请先登录');
            imgList.value.pop();
            Loading.value = false;
          } else if (xhr.response == '{"error":"app not auth","status":1014}') {
            ElMessage.warning('您似乎不是月卡用户哦~ 请先开通月卡');
            imgList.value.pop();
            Loading.value = false;
          } else if (xhr.response == '{"error":"channelId channel not available","status":2013}') {
            ElMessage.warning('通道繁忙,请稍后再试')
            imgList.value.pop()
            Loading.value = false;
          } else if (xhr.response == '{"error":"create error","status":2014}') {
            ElMessage.warning('生成结果包含敏感内容, 请重新绘制')
            imgList.value.pop()
            Loading.value = false;
          }
          else {
            ElNotification({
              title: '提示',
              message: '绘图已完成 ~',
              type: 'success',
            })
            getCurrentHistory();
            clearQiniu();
            paintingFinish.value = true;
          }
        }
      }
      const prompt = fixPrompt(mjprompt.value);
      
      
      let reqObj = {
        type: "generate",
        discordMsgId: "",
        msgHash: "",
        prompt: prompt,
        userId: userId,
        index: 0
      };
      //girl, white hair, red eyes, city
      objData && (reqObj = objData);
      xhr.send(JSON.stringify(reqObj));
    }
  }
}

const s3AccessKeyId = ref({});
const s3SecretAccessKey = ref({});
const s3SessionToken = ref({});

const getToken = () => {
  axios.post('/v1/trigger/token').then(res => {
    s3AccessKeyId.value = res.data.AccessKeyId;
    s3SecretAccessKey.value = res.data.SecretAccessKey;
    s3SessionToken.value = res.data.SessionToken;
  })
  // axios.post('/v1/trigger/token').then(res => {
  //   qiniuToken.value = res.data.token;
  // });
}

const clearQiniu = () => {
  uploadRef.value.clearFiles();
  qiniuImage.value = [];
  uploadListLen.value = 0;
}

const uploadListLen = ref(0);
const uploadList = ref([]);
const uploadQiniu = (imgSource, imgList) => {
  uploadList.value = [];
  imgList.forEach(item => uploadList.value.push(toRaw(item)));
  qiniuImage.value = [];
  if (imgList.length > 3) {
    ElNotification({
      title: '垫图上限提示',
      message: '最多只支持三张垫图哦~',
      type: 'warning',
    })
    imgList.pop();
  } else {
    imgList.forEach(item => {
      const file = toRaw(item).raw //Blob 对象，上传的文件
      const key = null  // （上传后的文件地址）上传后文件资源名以设置的 key 为主，如果 key 为 null 或者 undefined，则文件资源名会以 hash 值作为资源名。

      let config = {
        useCdnDomain: true,   //表示是否使用 cdn 加速域名，为布尔值，true 表示使用，默认为 false。
        region: null    // 根据具体提示修改上传地区,当为 null 或 undefined 时，自动分析上传域名区域
      };
      let putExtra = {
        fname: "",  //文件原文件名
        params: {}, //用来放置自定义变量
        mimeType: null  //用来限制上传文件类型，为 null 时表示不对文件类型限制；限制类型放到数组里： ["image/png", "image/jpeg", "image/gif"]
      };
      let observable = qiniu.upload(file, key, qiniuToken.value, putExtra, config);
      observable.subscribe({
          next: (res) => {
            let total = res.total;
            console.log("进度：" + parseInt(total.percent) + "% ")
          },
          error: (err) => console.log(err),
          complete: (res) => qiniuImage.value.push(`https://oss.topschat.com/${res.key}`)
      });
    });
  }
  uploadListLen.value = imgList.length;
}


const uploadS3 = (imgSource, imgList) => {
  const s3 = new AWS.S3({
    accessKeyId: s3AccessKeyId.value,
    secretAccessKey: s3SecretAccessKey.value,
    sessionToken: s3SessionToken.value
  });
  uploadList.value = [];
  imgList.forEach(item => uploadList.value.push(toRaw(item)));
  qiniuImage.value = [];
  if (imgList.length > 3) {
    ElNotification({
      title: '垫图上限提示',
      message: '最多只支持三张垫图哦~',
      type: 'warning',
    })
    imgList.pop();
  } else {
    uploadListLen.value = imgList.length;
    imgList.forEach((item, index) => {
      console.log('file', item);
      const uploadParams = {
        Bucket: BUCKET_NAME,
        Key: 'upload/' + item.name,
        Body: item.raw,
        ContentType: "image/png"
      };
      s3.upload(uploadParams, function (err, data) {
        if (err) {
          console.error("Error uploading data: ", err);
          return;
        }
        console.log("Upload succeeded:"+index, data.Location);
        qiniuImage.value.push(`https://oss2.topschat.com/upload/${item.name}`)
      });
    })
  }

}

const handleRemove = (file, fileList) => {
  const index = uploadList.value.findIndex(v => v.uid == file.uid);
  uploadList.value = [];
  fileList.forEach(item => uploadList.value.push(toRaw(item)));
  index != -1 && qiniuImage.value.splice(index, 1);
  uploadListLen.value = uploadList.value.length;
}

// ------------------------------------------------ 提取mj数据 ------------------------------------------------
const imgList = ref([]);
const getImgData = (data) => {
  const idRegex = /"id":"(\d+)"/g;
  const urlRegex = /"url":"([^"]+)"/g;
  const msgHashRegex = /"msgHash":"([^"]+)"/g;
  const sessionIDRegex = /"sessionID":"([^"]+)"/g;
  const typeRegex = /"type":"([^"]+)"/g;
  const progressRegex = /"progress":"([^"]+)"/g;
  const seedIdRegex = /"seedId":"([^"]+)"/g;

  let id, url, msgHash, sessionID, type, progress, seedId;

  let idMatch = idRegex.exec(data);
  while (idMatch) {
    id = idMatch[1];
    idMatch = idRegex.exec(data);
  }

  let seedIdMatch = seedIdRegex.exec(data);
  while (seedIdMatch) {
    seedId = seedIdMatch[1];
    seedIdMatch = seedIdRegex.exec(data);
  }

  let progressMatch = progressRegex.exec(data);
  while (progressMatch) {
    progress = progressMatch[1];
    progressMatch = progressRegex.exec(data);
  }

  let typeMatch = typeRegex.exec(data);
  while (typeMatch) {
    type = typeMatch[1];
    typeMatch = typeRegex.exec(data);
  }

  let urlMatch = urlRegex.exec(data);
  while (urlMatch) {
    url = urlMatch[1];
    urlMatch = urlRegex.exec(data);
  }

  let msgHashMatch = msgHashRegex.exec(data);
  while (msgHashMatch) {
    msgHash = msgHashMatch[1];
    msgHashMatch = msgHashRegex.exec(data);
  }

  let sessionIDMatch = sessionIDRegex.exec(data);
  while (sessionIDMatch) {
    sessionID = sessionIDMatch[1];
    sessionIDMatch = sessionIDRegex.exec(data);
  }
  if (url) {
    Loading.value = false;
    const obj = {};
    obj.url = url;
    obj.id = id;
    obj.msgHash = msgHash;
    obj.sessionID = sessionID;
    obj.prompt = fixPrompt(mjprompt.value);
    obj.type = type;
    obj.seedId = seedId;
    obj.progress = progress && progress.replace('%','');
    imgList.value[imgList.value.length - 1] = obj;
  }
  // girl, white hair, red eyes,  city
}
//------------------------------------------------ upscale ------------------------------------------------
const upscale = (obj) => {
  getMjData(obj);
}

//------------------------------------------------ variation ------------------------------------------------
const variation = (obj) => {
  getMjData(obj);
}

// ------------------------------------------------ reImagine ------------------------------------------------
const reImagine = (obj) => {
  getMjData(obj);
}

// ------------------------------------------------ deletehistory ------------------------------------------------
const deletehistory = (id) => {
  const index = imgList.value.findIndex(v => v.id == id);
  index != -1 && imgList.value.splice(index, 1);
  ElMessage.success('历史记录删除成功');
}

// ------------------------------------------------ GETHISTORY --------------------------------------------------
const getCurrentHistory = async () => {
  let resp = await axios.get('/api/v1/mjhistory?page=1&&count=10');
  if (resp.data.code == 200) {
    let currentImg = resp.data.data.data[0];
    imgList.value[imgList.value.length - 1].historyId = currentImg.id;
    imgList.value[imgList.value.length - 1].hd_image = currentImg.hd_image;
  }
}


// -------------------------------------------------- resolution -------------------------------------------------

const imgResolution = (imgRowData) => {
  ElMessageBox.confirm(
    '作品进行优化需要扣除2个代币噢，是否确定？',
    '提示',
    {
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      type: 'warning',
    }
  )
    .then(async () => {
      Loading.value = true;
      paintingFinish.value = false;
      imgList.value.push({
        url: '',
        id: '',
        msgHash: '',
        sessionID: ''
      });
      const data = {};
      data.id = imgRowData.historyId;
      data.url = imgRowData.hd_image;
      data.type = 'resolution'
      let resp = await axios.post('/v1/trigger/resolution', data);
      paintingFinish.value = true;
      Loading.value = false;
      if (resp.data.status == 1008) {
        ElMessage.warning('您的代币不足，作品优化失败')
        imgList.value.pop();
      } else {
        const obj = {};
        obj.url = resp.data.image;
        obj.id = '';
        obj.msgHash = '--';
        obj.sessionID = '--';
        obj.type = 'resolution';
        obj.seedId = '';
        obj.progress = '100';
        imgList.value[imgList.value.length - 1] = obj;
      }
    })
    .catch(() => {})
}

const imgQuality = async (imgRowData) => {
  ElMessageBox.confirm(
    '作品进行优化需要扣除2个代币噢，是否确定？',
    '提示',
    {
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      type: 'warning',
    }
  )
    .then(async () => {
      Loading.value = true;
      paintingFinish.value = false;
      imgList.value.push({
        url: '',
        id: '',
        msgHash: '',
        sessionID: ''
      });
      const data = {};
      data.id = imgRowData.historyId;
      data.url = imgRowData.hd_image;
      data.type = 'quality'
      let resp = await axios.post('/v1/trigger/resolution', data);
      paintingFinish.value = true;
      Loading.value = false;
      if (resp.data.status == 1008) {
        ElMessage.warning('您的代币不足，作品优化失败')
        imgList.value.pop();
      } else {
        const obj = {};
        obj.url = resp.data.image;
        obj.id = '';
        obj.msgHash = '--';
        obj.sessionID = '--';
        obj.type = 'quality';
        obj.seedId = '';
        obj.progress = '100';
        imgList.value[imgList.value.length - 1] = obj;
      }
    })
    .catch(() => {})
}

// ------------------------------------------------ 整理提示词 ------------------------------------------------
const portraitMode = ref(false);
const fixPrompt = (prompt) => {
  prompt += ' Â'
  // 风格prompt
  currType.value == 1 && (prompt += ' , watercolor');
  currType.value == 2 && (prompt += ' , oil painting');
  currType.value == 3 && (prompt += ' , Chinese painting');
  currType.value == 4 && (prompt += ' , illustration');
  currType.value == 5 && (prompt += ' --niji 5');
  currType.value == 6 && (prompt += ' , sketch');
  currType.value == 7 && (prompt += ' , 3d, cartoon');
  currType.value == 8 && (prompt += ' --Camera');
  // prompt += ' --fast';
  // 比例prompt
  currRate.value == 0 && (prompt += ' --ar 1:1');
  currRate.value == 1 && (prompt += ' --ar 16:9');
  currRate.value == 2 && (prompt += ' --ar 9:16');
  currRate.value == 3 && (prompt += ' --ar 5:7');
  currRate.value == 4 && (prompt += ' --ar 7:5');

  // 权重prompt
  if (qiniuImage.value.length > 0) {
    let qiniuPrompt = '';
    qiniuImage.value.forEach((item, index) => index == qiniuImage.value.length - 1 ? qiniuPrompt += `${item} ` : qiniuPrompt += `${item}, `);
    !portraitMode.value && ( prompt = `${qiniuPrompt} ${prompt} --iw ${qiniuiwval.value / 50}`);
    portraitMode.value && (prompt = `${prompt} --portraitMode ${qiniuPrompt} --portraitValue ${qiniuiwval.value}`)
  }
  
  // 反提示词
  negative_mjprompt.value != '' && (prompt += ` --no ${negative_mjprompt.value}`);
  ///////////////////////////////
  // 增加参数功能 2023/09/17
  if(!paramList.value){
    paramList.value = [];
  }
  paramList.value.forEach(item=> {
    prompt += ' ' + item.param + ' ' + item.value;
  });
  // console.log(prompt);
  ///////////////////////////////
  return prompt;
}

const backHome = () => {
  JumpTo('/')
}

const toBuy = () => {
  window.open('https://www.topschat.com/me/payMonth', '_blank')
  // window.open('http://uat.topschat.com/me/payMonth', '_blank');
  // router.push('/payMonth')
}

const getHistory = () => {
  // window.open('/draw', '_blank');
  router.push('/draw')
}

const tryTime = ref(0);
const getTryTimes = () => {
  axios.get(`/api/v1/trytime?appid=3`).then(res => {
    console.log('res', res);
    if(res.data.code == 200) {
      tryTime.value = res.data.data.appid_trial[0].try_time;
      console.log('trytime', tryTime.value);
    }
  })
}

const userInfo = ref({});
const getUserInfo = () => {
  axios.post('https://www.topschat.com/api/user/userInfo/detail', {}, {
    headers: {
      'Authorization': `Bearer ${getCookie("token")}`
    }
  }).then(res => {
    console.log('resss', res);
    if (res.data.code == 0) {
      userInfo.value = res.data.data
      userInfo.value.status = 2;
      console.log(userInfo.value.status)
    }
  })
  userInfo.value.status = 2;
}

onMounted(() => {
  document.title = "鼎言智绘";
  let token = getCookie("token");
  getTryTimes();
  getUserInfo();
  getToken();
  if (!token) {
    ElMessage.warning('检测到您还未登录，请先登录');
    // setTimeout(() => JumpTo('/login'), 3000);
    setTimeout(() => window.open('https://www.topschat.com', '_self'), 3000);
  }
});

</script>

<style scoped>
.topbar {
  background-color: #363d51;
  width: 100%;
  height: 65px;
  position: relative;
}

.logo {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 50px;
}

.styleImgDiv {
  display: inline-block;
  position: relative;
  border-radius: 5px;
  margin-left: 16px;
  margin-bottom: 10px;
  cursor: pointer;
}

/deep/.el-textarea__inner {
  color: #fff !important;
}

.loadingCss {
  background-color: #454545;
  width: 600px;
  height: 385px;
  margin: 70px;
  border-bottom: 1px solid rgb(87, 87, 87);
}

.imgContent {
  display: inline-block;
  width: calc(100% - 501px);
  height: calc(100vh - 1px);
  overflow-x: hidden;
  overflow-y: auto;
  float: right;
  border-left: 1px solid rgb(87, 87, 87);
  background-color: #222426;
}

.styleImg {
  border-radius: 5px;
  width: 70px;
  height: 70px;
  cursor: pointer;
}

.chosen-styleImg {
  border-radius: 5px;
  width: 67px;
  height: 67px;
  cursor: pointer;
  border: 2px solid #3e89d9;
}
/deep/.el-upload-dragger .el-icon--upload {
  margin-bottom: 0px !important;
}
.content {
  position: absolute;
  height: calc(100vh - 1px);
  overflow: auto;
  width: 500px;
  background-color: #222426;
}
/deep/.el-upload-dragger {
  background-color: #93989d1c !important
}

.el-textarea__inner {
  color: #fff;
}

.input-center {
  display: inline-block;
  height: 950px;
  width: 350px;
  /* width: 20%; */
  padding: 1%;
  padding-top: 0px;
  /* position: absolute; */
  left: 50px;
  /* background-color: aliceblue; */
}

.showItem {
  vertical-align: top;
  display: inline-block;
  /* width: 60%;  */
  width: calc(100% - 1000px);
  max-width: calc(100% - 250px);
  height: 100vh;
  padding: 10px;
  background-color: #222426;
  margin-left: 30px;
  border-left: 1px solid rgb(65, 65, 65);
}

/deep/.el-upload-dragger {
  height: 120px;
  padding-top: 10px;
}

.input {
  position: absolute;
  width: 90%;
  left: 50%;
  transform: translateX(-50%);
  bottom: 30px;
}

.tip-text {
  color: rgb(111, 112, 114);
  position: relative;
  top: 50%;
  font-size: 1.3em;
  padding-top: 2em;
  transform: translateY(-50%);
}

.input-area {
  width: 380px;
  height: 820px;
  border-top-right-radius: 30px;
  border: 1px solid #777777;
  padding: 0px 10px 10px 10px;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: 50px;
  background-color: #2f3441;
}

.tip {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(calc(-50% + 290px), -50%);
}

/deep/.el-input__wrapper {
  background-color: #42424261;
}

/deep/.el-textarea__inner {
  background-color: #26292b;
  height: 150px;
  border: none;
}

/deep/ .el-input__count {
  background-color: #26292b !important;
}

.choseTag {
  margin-left: 26px;
  width: 90px;
  font-weight: 700;
  background-color: #4396e9da;
  color: rgb(216, 238, 255);
  border: 1px solid #535353;
  cursor: pointer;
}

.overText {
  position: absolute;
  width: 100%;
  text-align: center;
  bottom: 3px;
  color: #fff;
  font-size: 0.3em;
  line-height: 22px;
  background-color: rgba(75, 74, 74, 0.637);
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}

.chosen-overText {
  position: absolute;
  width: 100%;
  text-align: center;
  bottom: 3px;
  color: #409eff;
  font-size: 0.3em;
  line-height: 22px;
  background-color: rgba(75, 74, 74, 0.637);
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}

/* 修改滚动条 */
::-webkit-scrollbar {
  width: 10px;
  /* 设置滚动条宽度 */
}

/deep/.el-slider__button {
  width: 15px !important;
  height: 15px !important;
}

/deep/.el-upload-list--picture-card {
  width: 120px !important;
  height: 120px !important;
}

/deep/.el-upload--picture-card {
  width: 120px !important;
  height: 120px !important;
}

/deep/.el-upload-list--picture-card .el-upload-list__item {
  width: 120px !important;
  flex: none !important;
}

/* 修改滚动条thumb（滑块）的颜色 */
::-webkit-scrollbar-thumb {
  background-color: rgb(141, 141, 141);
  /* 设置滚动条thumb的颜色 */
}

.unchoseTag {
  width: 90px;
  margin-left: 26px;
  font-weight: 700;
  background-color: rgb(61, 66, 71);
  color: rgb(122, 196, 253);
  border: 1px solid #535353;
  cursor: pointer;
}

.styleHeaderDiv{
  width:45%; 
  height:35px; 
  line-height:35px; 
  background-color: #1d222c;
  text-align: center; 
  color: #fff; 
  display: inline-block; 
  font-size: 0.9em;
}
.styleSelDiv{
  width:45%; 
  height:35px; 
  line-height:35px; 
  /* background-color: RGB(73,87,98);  */
  background-color: rgba(0, 0, 0, 0);
  color: #fff; 
  display: inline-block; 
  transform: translate(0%, -8%);
  font-size: 0.9em;
}


/*
 * 增加参数功能 2023/09/17
 */
/deep/.el-input {
  --el-input-border-radius:0;
}

/deep/.el-input__wrapper {
  box-shadow:0 0 0;
  background-color: rgba(0, 0, 0, 0);
}

/deep/.el-input__inner {
  color: #fff; 
  text-align: center; 
}
/*********** 增加参数功能 End  **********/
</style>