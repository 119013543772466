import axios from 'axios';
export function getCookie(cookieName) {
  const strCookie = document.cookie;
  const cookieList = strCookie.split(";");
  for (let i = 0; i < cookieList.length; i++) {
    const arr = cookieList[i].split("=");
    if (cookieName === arr[0].trim()) {
      return arr[1];
    }
  }
  return "";
}
export function isMobile() {
  let deviceName = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) ? 'Mobile' : 'Desktop';
  return deviceName === 'Mobile';
}
export function isMobileDevice() {
  let info = navigator.userAgent;
  return /mobile/i.test(info);
}
// 获取用户信息
export async function getUserInfo() {
  let userInfo;
  const token = getCookie("token");
  await axios.post('https://www.topschat.com/api/user/userInfo/detail', {}, {
    headers: {
      'Authorization': `Bearer ${token}`
    }
  }).then(res => {
    let { data } = res.data;
    userInfo = data;
  });
  return userInfo;
}

// 转化时间戳
export function timers(timestamp) {
  console.log(timestamp);
  if (timestamp) {
    var timer = new Date(timestamp);
    let year = timer.getFullYear();      //转换成年
    let month = timer.getMonth() + 1; //转换成月 ，月注意要加1，因为外国月份是0 - 11月
    let day = timer.getDate();  //转换成日
    let hours = timer.getHours();  //转换成时
    let minutes = timer.getMinutes();  //转换成分
    let secend = timer.getSeconds();  //转换成秒
    let str = `${year}-${transfer(month)}-${transfer(day)} ${transfer(hours)}:${transfer(minutes)}:${transfer(secend)}`;
    return str;
  } else {
    return '';
  }
}
function transfer(param) {
  return param > 9 ? param : '0' + param;
}

export function isNumber(variable) {
  return !isNaN(parseFloat(variable)) && isFinite(variable);
}
const testDomain = 'http://localhost:6061'
const prodDomain = 'https://www.topschat.com'
const Domain = process.env.NODE_ENV === 'development' ? testDomain : prodDomain
export function JumpTo(data) {
  // 当存在无界时
  if (window?.$wujie?.bus) {
    window.$wujie.bus.$emit('drawMessage', {data})
  } else {
    window.open(Domain + data, '_self');
  }
}
